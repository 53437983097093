import request from '@/utils/request'

const api = {
  userList: '/user/list',
  userPasswordSave: '/user/password/update',
  userNameSave: '/user/username/update',
  userRoleList: '/user/role/list',
  userRoleSave: '/user/role/update',
  roleList: '/role/list',
  rolePermissionList: '/role/permission/list',
  rolePermissionSave: '/role/permission/update',
  roleSave: '/role/update',
  roleDelete: '/role/delete',
  permissionList: '/permission/list',
  permissionSave: '/permission/update',
  permissionDelete: '/permission/delete'
}

export default api

export function getUserList (parameter) {
  return request({
    url: api.userList,
    method: 'get',
    params: parameter
  })
}

export function saveUserPassword (parameter) {
  return request({
    url: api.userPasswordSave,
    method: 'post',
    data: parameter
  })
}

export function saveUserName (parameter) {
  return request({
    url: api.userNameSave,
    method: 'post',
    data: parameter
  })
}
export function getUserRoleList (parameter) {
  return request({
    url: api.userRoleList,
    method: 'get',
    params: parameter
  })
}

export function saveUserRole (parameter) {
  return request({
    url: api.userRoleSave,
    method: 'post',
    data: parameter
  })
}

export function getRoleList (parameter) {
  return request({
    url: api.roleList,
    method: 'get',
    params: parameter
  })
}

export function getRolePermissionList (parameter) {
  return request({
    url: api.rolePermissionList,
    method: 'get',
    params: parameter
  })
}

export function saveRolePermission (parameter) {
  return request({
    url: api.rolePermissionSave,
    method: 'post',
    data: parameter
  })
}

export function saveRole (parameter) {
  return request({
    url: api.roleSave,
    method: 'post',
    data: parameter
  })
}

export function deleteRole (parameter) {
  return request({
    url: api.roleDelete,
    method: 'post',
    data: parameter
  })
}

export function getPermissionList (parameter) {
  return request({
    url: api.permissionList,
    method: 'get',
    params: parameter
  })
}

export function savePermission (parameter) {
  return request({
    url: api.permissionSave,
    method: 'post',
    data: parameter
  })
}

export function deletePermission (parameter) {
  return request({
    url: api.permissionDelete,
    method: 'post',
    data: parameter
  })
}

export function getServiceList (parameter) {
  return request({
    url: api.service,
    method: 'get',
    params: parameter
  })
}
