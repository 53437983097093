<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="权限">
              <a-input v-model="queryParam.name" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => this.queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.name"
      :columns="columns"
      :data="loadData"
    >
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="你确定要删除吗?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDelete(record)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>

    <permission-form
      ref="createModal"
      :visible="formVisible"
      :loading="confirmLoading"
      :model="formModel"
      @cancel="handleCancel"
      @ok="handleSave"
    />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { deletePermission, getPermissionList, savePermission } from '@/api/manage'
import PermissionForm from './modules/PermissionForm'

export default {
  name: 'UserList',
  components: {
    STable,
    PermissionForm
  },
  data () {
    return {
      formVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {},
      loadData: parameter => {
        return getPermissionList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '权限',
          dataIndex: 'name'
        },
        {
          title: '名称',
          dataIndex: 'label'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    handleAdd () {
      this.$refs.createModal.form.resetFields()
      this.formModel = null
      this.formVisible = true
    },
    handleEdit (record) {
      this.formModel = record
      this.formVisible = true
    },
    handleSave () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          savePermission(values).then(() => {
            this.formVisible = false
            this.confirmLoading = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success('修改成功')
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDelete (record) {
      deletePermission(record).then(() => {
        this.$message.success('删除成功')
        this.$refs.table.refresh()
      })
    },
    handleCancel () {
      this.formVisible = false
    }
  }
}
</script>

<style scoped>

</style>
